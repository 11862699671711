import { BrandConfigResponse } from '../../utils/configurations/brandConfig';

interface BannerDto {
  ctaKey: string;
  ctaUrl: string;
  titleKey: string;
  subtitleKey: string;
}

export type BrandConfig = {
  id: string;
  domain: string;
  language: string;
  country: string;
  currency: string;
  theme: {
    name: string;
    primaryColor: string;
    secondaryColor: string;
    primaryColorBg: string;
    primaryColorBgTextColor: string;
    accentColor: string;
    headerColor: string;
    themeName: string;
    favicon: string;
    logoPrimaryUrl: string;
    logoSecondaryUrl: string;
  };
  brand: {
    pageTitle: string;
    businessProfileIdH?: string;
    businessProfileIdDP?: string;
    businessProfileIdF?: string;
    businessProfileIdDPInF?: string;
    showAddHotelSwitchOnF: boolean;
    productTypesOrder: string[];
    productsInSelector: string[];
    phoneNumber?: string;
    privacyPolicyUrl?: string;
    phonePrivacyPolicyUrl?: string;
    brandName: string;
    businessClient: string;
    brandBase: string;
    brandOrigin: string;
    paymentMethods: any[];
    hasAuthentication: boolean; // TODO change to hasSSO
  };
  checkout?: {
    fiscalCodeRegEx?: string;
    showFiscalCode?: boolean;
    createReservationButtonText?: string;
  };
  landingContent?: {
    hero: {
      title: string;
      subtitle: string;
      backgroundUrl: string;
    };
    productExplanation: {
      title: string;
      subtitle: string;
      description: string;
    };
  };
  promoCampaign?: {
    banner?: {
      dp?: BannerDto;
      h?: BannerDto;
      f?: BannerDto;
    };
  };
  footer?: {
    embebed?: {
      scriptUrl?: string;
    };
    custom?: {
      legalText: string;
      termsAndConditionsUrl: string;
      privacyUrl: string;
      cookiesUrl: string;
      logoUrl: string;
    };
  };
};

export const configMapper = (info: BrandConfigResponse): BrandConfig => {
  const { id, domain, brandConfig } = info;

  const getBPByProduct = (productType: 'DP' | 'F' | 'H' | 'DPInF'): string => {
    if (productType === 'DPInF') {
      const hasFlightProduct = brandConfig.products.find(
        (p) => p.productType === 'F'
      );
      return hasFlightProduct
        ? hasFlightProduct.businessProfileIdDP
        : undefined;
    } else {
      const product = brandConfig.products.find(
        (p) => p.productType === productType
      );

      return product?.businessProfileId || undefined;
    }
  };

  const mapInfo = {
    id: id,
    domain: domain,
    language: brandConfig.brandDetails.language,
    country: brandConfig.brandDetails.country,
    currency: brandConfig.brandDetails.currency,
    theme: {
      ...brandConfig.theme.data.attributes,
      favicon: brandConfig.theme.data.attributes.favicon.data.attributes.url,
      logoPrimaryUrl:
        brandConfig.theme.data.attributes.logoPrimaryUrl.data.attributes.url,
      logoSecondaryUrl:
        brandConfig.theme.data.attributes.logoSecondaryUrl.data.attributes.url,
    },
    landingContent: {
      hero: {
        title: brandConfig.landingContent.hero.title,
        subtitle: brandConfig.landingContent.hero.subtitle,
        backgroundUrl:
          brandConfig.landingContent.hero.hero_background.data.attributes.url,
      },
      productExplanation: {
        title: brandConfig.landingContent.productExplanation.title,
        subtitle: brandConfig.landingContent.productExplanation.subtitle,
        description: brandConfig.landingContent.productExplanation.description,
      },
    },
    brand: {
      pageTitle: brandConfig.brandDetails.pageTitle,
      businessProfileIdH: getBPByProduct('H'),
      businessProfileIdDP: getBPByProduct('DP') || getBPByProduct('DPInF'),
      businessProfileIdF: getBPByProduct('F'),
      showAddHotelSwitchOnF: !!getBPByProduct('DPInF'),
      productTypesOrder: brandConfig.products.map((item) => item.productType),
      productsInSelector: brandConfig.products
        .filter((i) => i.showInSelector)
        .map((item) => item.productType),
      phoneNumber: brandConfig.brandDetails.phoneNumber,
      privacyPolicyUrl: brandConfig.brandDetails.footer.privacyUrl,
      phonePrivacyPolicyUrl: brandConfig.brandDetails.phonePrivacyPolicyUrl,
      brandName: brandConfig.brandName,
      businessClient: brandConfig.businessClient,
      brandBase: brandConfig.brandDetails.brandBase,
      brandOrigin: brandConfig.brandDetails.brandOrigin,
      paymentMethods: brandConfig.payment_methods.data,
      hasAuthentication: brandConfig.hasTokenAuthentication,
    },
    checkout: {
      fiscalCodeRegEx: brandConfig.checkoutOptions?.fiscalCodeRegEx,
      showFiscalCode: brandConfig.checkoutOptions?.showFiscalCodeInput,
      createReservationButtonText:
        brandConfig.checkoutOptions?.createReservationButtonText ||
        'form.button.confirm_reservation',
    },
    promoCampaign: {
      banner: {
        dp: {
          ctaKey: brandConfig.promo_campaign?.data?.attributes.bannerDP?.ctaKey,
          ctaUrl: brandConfig.promo_campaign?.data?.attributes.bannerDP?.ctaUrl,
          titleKey:
            brandConfig.promo_campaign?.data?.attributes.bannerDP?.titleKey,
          subtitleKey:
            brandConfig.promo_campaign?.data?.attributes.bannerDP?.subtitleKey,
        },
        h: {
          ctaKey: brandConfig.promo_campaign?.data?.attributes.bannerH?.ctaKey,
          ctaUrl: brandConfig.promo_campaign?.data?.attributes.bannerH?.ctaUrl,
          titleKey:
            brandConfig.promo_campaign?.data?.attributes.bannerH?.titleKey,
          subtitleKey:
            brandConfig.promo_campaign?.data?.attributes.bannerH?.subtitleKey,
        },
        f: {
          ctaKey: brandConfig.promo_campaign?.data?.attributes.bannerF?.ctaKey,
          ctaUrl: brandConfig.promo_campaign?.data?.attributes.bannerF?.ctaUrl,
          titleKey:
            brandConfig.promo_campaign?.data?.attributes.bannerF?.titleKey,
          subtitleKey:
            brandConfig.promo_campaign?.data?.attributes.bannerF?.subtitleKey,
        },
      },
    },
    footer: {
      custom: {
        legalText: brandConfig.brandDetails.footer.legalTextKey,
        termsAndConditionsUrl:
          brandConfig.brandDetails.footer.termsAndConditionsUrl,
        privacyUrl: brandConfig.brandDetails.footer.privacyUrl,
        cookiesUrl: brandConfig.brandDetails.footer.cookiesUrl,
        logoUrl: brandConfig.brandDetails.footer.logo?.data?.attributes.url,
      },
      embebed: {
        scriptUrl: brandConfig.brandDetails.footer.externalFooter,
      },
    },
  };
  console.log('mapInfo', mapInfo);

  return mapInfo;
};
