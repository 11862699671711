import { Sort } from '../../../services/codegen-romulo';

const getSortKeyByTabIndex = (tabIndex: string): Sort => {
  switch (tabIndex) {
    case 'RECOMMENDED':
      return { name: 'RECOMMENDED', ascending: true };
    case 'PRICE':
      return { name: 'PRICE', ascending: true };
    case 'DURATION':
      return { name: 'DURATION', ascending: true };
    case 'TOP_RATED':
      return { name: 'TOP_RATED', ascending: false };
    case 'TRANSPORT_PRICE':
      return { name: 'TRANSPORT_PRICE', ascending: true };
    case 'TRANSPORT_RECOMMENDED':
      return { name: 'TRANSPORT_RECOMMENDED', ascending: true };
    case 'TRANSPORT_FASTEST':
      return { name: 'TRANSPORT_FASTEST', ascending: true };
  }
  return { name: 'RECOMMENDED', ascending: false };
};

export default getSortKeyByTabIndex;
